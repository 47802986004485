<template lang="pug">
.outlined-label(
  :class="{'active': active}"
  @click="$emit('click')"
)
  slot {{ text }}
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    active: Boolean
  }
}
</script>

<style lang="scss" scoped>
.outlined-label {
  border-radius: 3px;
  font-weight: bold;
  color: $primary-color;
  border: 1px solid $primary-color;
  height: 36px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  line-height: 1;
  align-items: center;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  word-break: break-all;
  cursor: pointer;

  &:hover {
    @extend .active;
  }

  &.active {
    background-color: $primary-color;
    color: #fff;
  }
  
}
</style>